/**
 * Store reducer
 * Handles login/logout actions
 */

import { createAction, handleActions } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------
export const ALGOLIA_DATA_SAVE = "ALGOLIA_DATA_SAVE";
export const ALGOLIA_CLEAR = "ALGOLIA_CLEAR";

// ------------------------------------
// Actions
// ------------------------------------
const saveAlgoliaData = createAction(ALGOLIA_DATA_SAVE);
const clearAlgoliaData = createAction(ALGOLIA_CLEAR);

export const actions = {
  saveAlgoliaData,
  clearAlgoliaData
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFirestore: false,
  keys: {
    salesApiKey: '',
    salesAppId: '',
    productsApiKey: '',
    productsAppId: '',
    categoriesApiKey: '',
    categoriesAppId: '',
  }
};

export default handleActions(
  {
    ALGOLIA_DATA_SAVE: (state, { payload }) => {
      //payload = store
      return {
        ...state,
        ...payload //toDO check
      };
    },
    ALGOLIA_CLEAR: () => {
      return {
        ...initialState
      };
    },
  },
  initialState
);
