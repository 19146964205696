/**
 * Combine reducers
 */

import { combineReducers } from "redux";
import account from "./account";
import process from "./process";
import user from "./user";
import nav from "./nav";
import reports from "./reports";
import subscription from "./subscription";
import aborted from "./aborted";
import appearance from "./appearance"
import algolia from "./algolia";

export default combineReducers({
  account,
  nav,
  process,
  user,
  reports,
  subscription,
  aborted, 
  appearance,
  algolia
});
